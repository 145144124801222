import BusinessUserType from '@/utils/types/businessUser.type';
import { permissions } from '@Utils/constants/roles';
import routeConstants from '@Utils/constants/routes';
import Router from 'next/router';
import useAuthUser from '@Hooks/authUser';
import { useEffect, useState } from 'react';

interface RestrictFeatureProps {
  children: JSX.Element;
  feature: string;
  shouldRedirect?: boolean;
}
export const checkAuthorizedForFeature = ({
  feature,
  user,
}: {
  feature: string;
  user?: BusinessUserType | null;
}) => {
  let grantedPermission = false;
  if (user) {
    const allowedFeatures = permissions[user.role];
    if (allowedFeatures && allowedFeatures.includes(feature)) {
      grantedPermission = true;
    }
  }
  return grantedPermission;
};

const RestrictedFeature = ({
  children,
  feature,
  shouldRedirect,
}: RestrictFeatureProps) => {
  const { businessUser: user } = useAuthUser();
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    if (user) {
      const grantedPermission = checkAuthorizedForFeature({
        feature,
        user,
      });
      setIsAuthorized(grantedPermission);
      if (shouldRedirect && !grantedPermission) {
        Router.push(routeConstants.PAGE_RESTRICTED);
      }
    }
  }, [user, feature, shouldRedirect]);

  return isAuthorized ? children : null;
};

export default RestrictedFeature;
