import config from '@/config';
import Http from '@Services/Http';
import Logger from '@/services/Logger';

async function getBusiness(id: string) {
  Logger.debug('Getting business', { id });
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    json: true,
    method: 'GET',
    url: `${config.api.auraServices}/business/${id}`,
  };
  const response = await Http.handle(options);

  if (response && response.status === 200 && response.data) {
    return response.data;
  }
}

getBusiness.QUERY_STRING = 'get-business';

export { getBusiness };
