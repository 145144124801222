import classnames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import RouteInfo from '../navItem.type';
import styles from './styles.module.scss';
import useDeviceDetect from '@Hooks/useDeviceDetect';

interface Props {
  item: RouteInfo;
  active?: boolean;
}

function NavBarItem({ active, item }: Props): JSX.Element {
  const isMobile = useDeviceDetect();

  return (
    <Link key={item.route} href={item.route} style={{ textDecoration: 'none' }}>
      <div
        className={classnames(styles.navItem, { [styles.activeItem]: active })}>
        <Image
          alt={item.title}
          height={isMobile ? 24 : 14}
          src={active ? item.activeIcon : item.icon}
          width={isMobile ? 24 : 14}
        />
        <div>{item.title}</div>
      </div>
    </Link>
  );
}

export default NavBarItem;
