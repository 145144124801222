import Analytics from '@/services/Analytics';
import Auth from '@/services/Auth';
import { AxiosError } from 'axios';
import { getBusiness } from '@/models/business';
import { getBusinessUserList } from '@/models/businessUser';
import { notifyAPIError } from '@/services/ErrorMonitoring';
import routeConstants from '@/utils/constants/routes';
import Router from 'next/router';
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

export default function useAuthUser() {
  const loginSession = Auth.getCurrentLoginSession();
  const { id: userId } = loginSession || {};

  const handleError = (error: AxiosError, apiName: string) => {
    notifyAPIError(error as AxiosError, {
      message: `Error while getting ${apiName}`,
    });
    const { response } = error;
    const { status } = response || {};
    if (status === 403 || status === 401) {
      Auth.logout({ redirectToLogin: true });
      Router.push({
        pathname: routeConstants.PAGE_LOGIN,
        query: {
          asPath: Router.asPath,
          pathname: Router.pathname,
        },
      });
    } else {
      Router.push(routeConstants.PAGE_RESTRICTED);
    }
  };

  const {
    data: businessUserList,
    isInitialLoading: isLoadingBusinessUser,
    isSuccess: isFetchedBusinessUser,
  } = useQuery({
    enabled: !!userId,
    onError: (error: AxiosError) => handleError(error, 'business user list'),
    queryFn: () => getBusinessUserList(userId),
    queryKey: [getBusinessUserList.QUERY_STRING, userId],
  });

  const businessUser =
    businessUserList && businessUserList.length ? businessUserList[0] : null;
  const businessId = businessUser ? businessUser.businessId : null;

  const {
    data: business,
    isInitialLoading: isLoadingBusiness,
    isSuccess: isFetchedBusiness,
  } = useQuery({
    enabled: !!businessId,
    onError: (error: AxiosError) => handleError(error, 'business details'),
    queryFn: () => {
      if (businessId) {
        return getBusiness(businessId);
      }
    },
    queryKey: [getBusiness.QUERY_STRING, businessId],
  });

  useEffect(() => {
    if (businessUser) Analytics.identifyBusinessUser(businessUser);
  }, [businessUser]);

  useEffect(() => {
    if (!userId) {
      Router.replace(routeConstants.PAGE_LOGIN);
    }
  }, [userId]);

  useEffect(() => {
    if (
      (isFetchedBusiness && !business) ||
      (isFetchedBusinessUser && !businessUser)
    ) {
      Router.push(routeConstants.PAGE_RESTRICTED);
    }
  }, [businessUser, business, isFetchedBusiness, isFetchedBusinessUser]);

  return {
    business,
    businessUser,
    isLoading: isLoadingBusiness || isLoadingBusinessUser,
  };
}
