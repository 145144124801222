import features from './features';

const roles = {
  ADMIN: 'admin',
  OWNER: 'owner',
};

const permissions = {
  [roles.ADMIN]: Object.values(features),
  [roles.OWNER]: Object.values(features),
};
export { permissions, roles };
