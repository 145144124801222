import AuraRing from '../AuraRing';
import Auth from '@/services/Auth';
import classnames from 'classnames';
import Image from 'next/image';
import { IoIosLogOut } from 'react-icons/io';
import NavBarItem from './NavBarItem';
import routeConstants from '@/utils/constants/routes';
import RouteInfo from './navItem.type';
import styles from './styles.module.scss';
import useDeviceDetect from '@Hooks/useDeviceDetect';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

function NavBar(): JSX.Element {
  const { pathname } = useRouter();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const isMobile = useDeviceDetect();

  const navItems: RouteInfo[] = useMemo(
    () => [
      {
        activeIcon: '/navIcons/homeIcon.svg',
        icon: '/navIcons/homeIconBlack.svg',
        route: routeConstants.PAGE_DASHBOARD,
        title: 'Dashboard',
      },
      {
        activeIcon: '/navIcons/downloadIcon.svg',
        icon: '/navIcons/downloadIconBlack.svg',
        route: routeConstants.PAGE_MEMBERS,
        title: 'Members',
      },
      // {
      //   activeIcon: '/navIcons/userIcon.svg',
      //   icon: '/navIcons/userIconBlack.svg',
      //   route: routeConstants.PAGE_BILLING,
      //   title: 'Billing',
      // },
      // {
      //   activeIcon: '/navIcons/settingIcon.svg',
      //   icon: '/navIcons/settingIconBlack.svg',
      //   route: routeConstants.PAGE_SETTINGS,
      //   title: 'Settings',
      // },
    ],
    [pathname]
  );

  useEffect(() => {
    if (!isMobile) setIsMobileMenuOpen(false);
  }, [isMobile]);

  return (
    <aside>
      <div className={styles.navMain}>
        <div className={styles.head}>
          <div className={styles.iconArea}>
            <AuraRing size={33} />
            <h1 className={styles.title}>Aura</h1>
          </div>
          {isMobile && (
            <div>
              <Image
                alt={isMobileMenuOpen ? 'close' : 'Menu'}
                className="clickable"
                height={20}
                src={
                  isMobileMenuOpen ? 'icons/close.svg' : 'icons/hamburger.svg'
                }
                width={20}
                onClick={() => {
                  setIsMobileMenuOpen(!isMobileMenuOpen);
                }}
              />
            </div>
          )}
        </div>
        <div
          className={classnames(styles.navContainer, {
            [styles.openNavMobile]: isMobileMenuOpen,
          })}>
          <div
            className={classnames(styles.navItemList, {
              [styles.openNavBarList]: isMobileMenuOpen,
            })}>
            <div className={styles.navItems}>
              {navItems.map((item) => (
                <NavBarItem
                  key={item.title}
                  active={pathname === item.route}
                  item={item}
                />
              ))}
            </div>
            <div
              className={classnames('clickable', styles.logoutButton)}
              onClick={() => {
                Auth.logout({ redirectToLogin: true });
              }}>
              <IoIosLogOut size={isMobile ? 28 : 16} />
              <div>Log out</div>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}

export default NavBar;
