import axios from 'axios';
import config from '@/config';

async function fetchMember(id: string) {
  if (!id) return;

  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    json: true,
    method: 'GET',
    url: `${config.api.auraServices}/business/members/${id}`,
  };
  const response = await axios(options);

  if (response && response.status === 200 && response.data) {
    return response.data;
  }
}

async function fetchMembersList({
  businessId,
  status,
}: {
  businessId?: string;
  status?: string;
}) {
  if (!businessId) return;
  const data = { businessId, status };

  const options = {
    data,
    headers: {
      'Content-Type': 'application/json',
    },
    json: true,
    method: 'POST',
    url: `${config.api.auraServices}/business/members/list`,
  };
  const response = await axios(options);

  if (response && response.status === 200 && response.data) {
    return response.data;
  }
}

async function memberInvite({
  businessId,
  email,
  name,
}: {
  businessId: string;
  email: string;
  name?: string;
}) {
  const data = { businessId, email, name };

  const options = {
    data,
    headers: {
      'Content-Type': 'application/json',
    },
    json: true,
    method: 'POST',
    url: `${config.api.auraServices}/business/members/invite`,
  };
  const response = await axios(options);

  if (response && response.status === 200 && response.data) {
    return response.data;
  }
}

async function memberInviteBulk({
  businessId,
  members,
}: {
  businessId: string;
  members: { email?: string; name?: string }[];
}) {
  const data = { businessId, members };

  const options = {
    data,
    headers: {
      'Content-Type': 'application/json',
    },
    json: true,
    method: 'POST',
    url: `${config.api.auraServices}/business/members/inviteBulk`,
  };
  const response = await axios(options);

  if (response && response.status === 200 && response.data) {
    return response.data;
  }
}

async function deletedMember(id: string | undefined) {
  if (!id) {
    throw new Error('Businessid required');
  }
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    json: true,
    method: 'DELETE',
    url: `${config.api.auraServices}/business/members/${id}`,
  };
  const response = await axios(options);

  if (response && response.status === 200 && response.data) {
    return response.data;
  }
}

async function applyBusinessSubscription({
  businessId,
  memberId,
}: {
  businessId: string;
  memberId: string;
}) {
  const data = { businessId, memberId };

  const options = {
    data,
    headers: {
      'Content-Type': 'application/json',
    },
    json: true,
    method: 'POST',
    url: `${config.api.auraServices}/receipts/businessMembers`,
  };
  const response = await axios(options);

  if (response && response.status === 200 && response.data) {
    return response.data;
  }
}

fetchMember.QUERY_STRING = 'fetch-member';
fetchMembersList.QUERY_STRING = 'members-list';

export {
  deletedMember,
  fetchMember,
  fetchMembersList,
  memberInvite,
  memberInviteBulk,
  applyBusinessSubscription,
};
