import Analytics from '../services/Analytics';
import Auth from '@/services/Auth';
import routeConstants from '../utils/constants/routes';
import Router from 'next/router';
import { useEffect, useRef } from 'react';

/**
 * React Hook to send page view events
 * @param {string} event - Name of event to fire
 * @param {Object} data - Date to send with the event
 * @param {string[]} dependencies - List of dependencies. Each dependency should not be null for the event to be sent
 */
function useTrackPageView(
  data: Record<string, any> = {}, // eslint-disable-line @typescript-eslint/no-explicit-any
  dependencies: Array<any> = [] // eslint-disable-line @typescript-eslint/no-explicit-any
) {
  const isEventSent = useRef(false);
  const loginSession = Auth.getCurrentLoginSession();

  useEffect(() => {
    if (!isEventSent.current) {
      if (dependencies.every((item) => !!item)) {
        const eventData = data;
        if (!data['Page Name']) {
          // Use page name from route path if not specified specifically in event data
          eventData['Page Name'] =
            routeConstants.PAGE_NAMES[
              Router.pathname as keyof typeof routeConstants.PAGE_NAMES
            ];
        }
        eventData['Page Path'] = Router.pathname;
        if (loginSession) {
          const { displayName: givenName, email, id } = loginSession;
          Analytics.signIn({ email, givenName, id });
          eventData.UserID = id;
        }
        Analytics.track('Web Page View', eventData);
        isEventSent.current = true;
      }
    }
  }, [data, dependencies, loginSession]);
}

export default useTrackPageView;
