import BaseLayout from '../BaseLayout';
import features from '@Utils/constants/features';
import Loader from '@AppComponents/Loader';

import RestrictedFeature from '@AppComponents/RestrictedFeature';
import useAuthUser from '@Hooks/authUser';

interface Props {
  children: React.ReactNode;
  hideBackground?: boolean;
  navBackground?: boolean;
}

const LayoutWithAuth = ({ children, hideBackground, navBackground }: Props) => {
  const { businessUser, isLoading } = useAuthUser();
  if (!businessUser || isLoading) {
    return (
      <BaseLayout hideBackground={hideBackground}>
        <Loader text="Logging in, Please Wait..." />
      </BaseLayout>
    );
  }
  return (
    <RestrictedFeature feature={features.PLATFORM_ACCESS} shouldRedirect>
      <BaseLayout hideBackground={hideBackground} navBackground={navBackground}>
        {children}
      </BaseLayout>
    </RestrictedFeature>
  );
};

export default LayoutWithAuth;
