import classnames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

interface Props {
  cardStyle?: React.CSSProperties;
  children: React.ReactNode;
  className?: string;
}

function Card({ cardStyle, children, className }: Props): JSX.Element {
  return (
    <div
      className={classnames(styles.cardWrapper, className)}
      style={cardStyle}>
      {children}
    </div>
  );
}

export default Card;
