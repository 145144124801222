import Pulse from '../Pulse';
import React from 'react';
import styles from './styles.module.scss';

const DEFAULT_LOADER_COLOR = '#03a9f4';
const DEFAULT_LOADER_SIZE = 100;

interface Props {
  size?: number;
  color?: string;
  inline?: boolean;
  text?: string;
  style?: React.CSSProperties;
}

export default function Loader({ color, inline, size, style, text }: Props) {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: inline ? 'auto' : '100vh',
        justifyContent: 'center',
        width: inline ? 'auto' : '100%',
        ...(style || {}),
      }}>
      <Pulse
        color={color || DEFAULT_LOADER_COLOR}
        size={size || DEFAULT_LOADER_SIZE}
      />

      {text && <h4 className={styles.text}>{text}</h4>}
    </div>
  );
}
