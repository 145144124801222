import { AxiosError } from 'axios';
import { fetchMembersList } from '@/models/members';
import MemberType from '@/utils/types/members.type';
import { notifyAPIError } from '@/services/ErrorMonitoring';
import useAuthUser from './authUser';
import { useQuery } from '@tanstack/react-query';

export default function useMembers() {
  const { business } = useAuthUser();
  const { id: businessId } = business || {};

  const transformData = (data: MemberType[]) => {
    if (!data.length) return null;
    const confirmedMemberList = data.filter(
      (member) => member.status === 'confirmed'
    );

    return {
      confirmedMemberList,
      membersList: data,
    };
  };

  const { data, isError, isInitialLoading } = useQuery({
    enabled: !!businessId,
    onError: (error: AxiosError) => {
      notifyAPIError(error as AxiosError, {
        message: 'Error while getting members list',
      });
    },
    queryFn: () => fetchMembersList({ businessId: businessId }),
    queryKey: [fetchMembersList.QUERY_STRING, businessId],
    select: transformData,
  });

  return {
    confirmedMemberList: data?.confirmedMemberList,
    isError,
    membersList: data?.membersList,
    membersLoading: isInitialLoading,
  };
}
