import LayoutWithAuth from '../LayoutWithAuth';
import NavBar from '@AppComponents/NavBar';
import React from 'react';
import styles from './styles.module.scss';

interface Props {
  children: React.ReactNode;
  hideBackground?: boolean;
  navBackground?: boolean;
}

function LayoutWithNav({ children }: Props): JSX.Element {
  return (
    <LayoutWithAuth hideBackground navBackground>
      <NavBar />

      <main className={styles.contentWithNav}>{children}</main>
    </LayoutWithAuth>
  );
}

export default LayoutWithNav;
