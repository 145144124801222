import config from '@/config';
import Http from '@/services/Http';
import Logger from '@/services/Logger';

async function getBusinessUserList(userId: string | undefined) {
  Logger.debug('Getting business user list', { userId });
  const data = { userId };
  const options = {
    data,
    headers: {
      'Content-Type': 'application/json',
    },
    json: true,
    method: 'POST',
    url: `${config.api.auraServices}/business/users/list`,
  };
  const response = await Http.handle(options);
  if (response && response.status === 200 && response.data) {
    Logger.debug('Got business user list', { data: response.data });
    return response.data;
  }
}
getBusinessUserList.QUERY_STRING = 'business-user-list';

export { getBusinessUserList };
