import Image from 'next/image';

import React from 'react';

interface Props {
  className?: string;
  size?: number;
  style?: React.CSSProperties;
}

export default function AuraRing({
  className,
  size = 72,
  style,
}: Props): JSX.Element {
  return (
    <Image
      alt="Aura Logo"
      className={className || ''}
      height={size}
      src="/icons/auraLogo.png"
      style={{
        objectFit: 'contain',
        ...style,
      }}
      width={size}
    />
  );
}
