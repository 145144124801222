import styles from './styles.module.scss';

interface Props {
  size: string | number;
  color: string | number;
}

export default function Pulse({ color, size }: Props): JSX.Element {
  return (
    <div
      className={styles.loader}
      style={{
        background: color,
        height: size,
        width: size,
      }}></div>
  );
}
